<template>
    <div class="mb-6 pa-4 mt-3">
        <h4>Lentes de Contacto</h4>
        <v-row dense :class="$vuetify.breakpoint.smAndDown ? 'mt-n2 mb-n3' : 'mb-n3 mt-n2'">
            <v-col cols="12" md="6" xs="12">
                <v-btn color="success" class="btn mt-2" block small @click="nuevo()">
                    <v-icon dark left>
                        mdi-spray-bottle
                    </v-icon> Nuevo
                </v-btn>
            </v-col>
            <v-col cols="12" md="6" xs="12">
                <v-text-field outlined dense v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
            </v-col>
        </v-row>
        <v-card class="pa-3">

            <v-data-table :headers="headers" :search="buscar" :items="listafiltrada" dense :items-per-page="17"
                mobile-breakpoint="1">
                <template v-slot:item.actions="{ item }">
                    <v-row>
                        <v-col cols="12">
                            <v-icon color="green" @click="editar(item)">mdi-lead-pencil</v-icon>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="dialogo_agrega" max-width="700px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dialogo_agrega = !dialogo_agrega">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="agregar()">mdi-content-save</v-icon>
                    <v-icon color="red" large @click="dialogo_elimina = !dialogo_elimina">mdi-delete</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-4">

                <v-row>
                    <v-col cols="6" sm="6" md="6">
                        <v-select disabled :items="arraytipoProducto" label="Tipo" dense outlined
                            v-model="tipoproducto"></v-select>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-select disabled :items="arrayOperacion" label="Operacion" dense outlined
                            v-model="operacion"></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n9">
                    <v-col cols="6">
                        <v-text-field dense disabled v-model="id" label="ID" outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined dense type="text" v-model="marca" label="Marca">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="6">
                        <v-select :items="array_tratamiento" label="Tratamiento" dense outlined
                            v-model="tratamiento"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="array_contenido" label="Contenido" dense outlined
                            v-model="contenido"></v-select>

                    </v-col>
                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="12">
                        <v-textarea dense outlined v-model="descripcion" auto-grow filled label="Producto"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="stock" label="Stock"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="costo" label="costo"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="precio" label="Precio venta"></v-text-field>
                    </v-col>
                    <v-col cols="6">

                    </v-col>
                </v-row>

            </v-card>

        </v-dialog>
        <v-dialog v-model="dialogo_elimina" max-width="300">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_elimina = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <h4 class="text-center">Esta seguro de Eliminar?</h4>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn block @click="eliminar()" color="orange">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import {
    nuevo_lente_c,
    elimina_lente_c
} from '../../db'
import {
    obten_contador_externo,
    sumarCorrelativo_externo
} from '../../contadores'
import store from '@/store/index'
export default {
    name: 'caja',
    components: {

    },
    data() {
        return {
            headers: [{
                text: 'id',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            {
                text: 'Marca',
                value: 'marca',
            },
            {
                text: 'Tratamiento',
                value: 'tratamiento',
            },
            {
                text: 'Producto',
                value: 'descripcion',
            },
            {
                text: 'Contenido',
                value: 'contenido',
            },
            {
                text: 'Precio',
                value: 'precio',
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            },
            ],
            activa_productos: false,
            dialogo_agrega: false,
            dialogo_elimina: false,
            array_tabla: [],
            arraytipoProducto: ['BIEN', 'SERVICIO'],
            tipoproducto: "BIEN",
            arrayOperacion: [
                'GRAVADA',
                'EXONERADA',
                'INAFECTA'
            ],
            operacion: "GRAVADA",
            edit: false,
            id: '',
            tratamiento: 'ESFERA',
            array_tratamiento: ['ESFERA', 'ASTIGMATISMO'],
            array_contenido: ['1 UNIDAD', '2 UNIDADES', '6 UNIDADES', '30 UNIDADES'],
            contenido: '6 UNIDADES',
            marca: '',
            descripcion: '',
            costo: '',
            stock: '',
            precio: '',
            buscar: '',
        }
    },
    created() {
        this.array_tabla = store.state.lentes_c
    },

    computed: {
        listafiltrada() {
            this.array_tabla = store.state.lentes_c
            return this.array_tabla
        }
    },

    methods: {

        selec_producto(data) {
            this.marca = data.nombre
            this.activa_productos = false
        },
        async nuevo() {
            this.edit = false
            var a = await obten_contador_externo("orden_lentes_c")
            this.id = "L" + a
            this.marca = ''
            this.descripcion = ''
            this.contenido = '6 UNIDADES'
            this.stock = 1
            this.costo = 0
            this.precio = 0
            this.dialogo_agrega = true
        },
        async agregar() {
            if (!this.edit) {
                var a = await obten_contador_externo("orden_lentes_c")
                this.id = "L" + a
            }
            store.commit("dialogoprogress", 1)
            var array = {
                tipoproducto: this.tipoproducto,
                operacion: this.operacion,
                id: this.id,
                marca: this.marca,
                tratamiento: this.tratamiento,
                contenido: this.contenido,
                descripcion: this.descripcion,
                costo: this.costo,
                precio: this.precio,
                stock: this.stock,
            }
            await nuevo_lente_c(this.id, array)
            store.commit("dialogoprogress", 1)
            if (!this.edit) {
                await sumarCorrelativo_externo("orden_lentes_c", a)
                this.marca = ''
                this.descripcion = ''
                this.stock = 1
                this.costo = 0
                this.precio = 0
                this.dialogo_agrega = false
            } else {
                this.dialogo_agrega = false
            }
        },
        editar(data) {
            this.edit = true
            this.id = data.id
            this.tratamiento = data.tratamiento
            this.marca = data.marca
            this.descripcion = data.descripcion
            this.contenido = data.contenido
            this.stock = data.stock
            this.costo = data.costo
            this.precio = data.precio
            this.dialogo_agrega = true
        },
        eliminar() {
            elimina_lente_c(this.id)
            this.dialogo_elimina = false
            this.dialogo_agrega = false
        },
        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>
